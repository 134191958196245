import React from "react"

import Link from "decentraland-gatsby/dist/components/Text/Link"
import MainTitle from "decentraland-gatsby/dist/components/Text/MainTitle"
import Paragraph from "decentraland-gatsby/dist/components/Text/Paragraph"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import Layout from "../components/Layout/Layout"
import logo from "../images/logo.svg"

import "./press.css"

export default function PressPage(props: any) {
  const trackClick = useTrackLinkContext()
  /* TODO: remove page__press class when UI2 migration is completed */
  return (
    <Layout
      {...props}
      className={TokenList.join([props.className, "page__press"])}
    >
      <Container>
        <img
          className="logo"
          loading="lazy"
          src={logo}
          width="400"
          height="400"
        />
        <MainTitle>Decentraland Press Kit</MainTitle>
        <Paragraph>
          We've put together all sorts of goodies that you're welcome to use
          when writing and talking about our project. Please direct any
          questions to{" "}
          <Link href="mailto:press@decentraland.org">
            press@decentraland.org
          </Link>
          .
        </Paragraph>
        <Button
          onClick={trackClick}
          as={Link}
          primary
          href="https://dcl.gg/press"
        >
          Download Press Kit
        </Button>
      </Container>
    </Layout>
  )
}
